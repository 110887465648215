import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/login";

Vue.use(VueRouter);

const routes = [
  { path: "/", redirect: "/login" },
  {
    path: '/meeting',
    name: 'Meeting',
    component: () => import('../views/meeting')
  },
  {
    path: '/photo',
    name: 'Photo',
    component: () => import('../views/photo')
  }
  , {
    path: '/user',
    name: 'User',
    component: () => import('../views/user')
  },{
    path: '/video',
    name: 'Video',
    component: () => import('../views/video')
  },{
    path: '/home',
    name: 'Home',
    component: () => import('../views/home')
  },
    {
        path: '/final',
        name: 'Final',
        component: () =>
            import ('../views/final')
    },
//
//
  {
    path: "/login",
    component: Login,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
