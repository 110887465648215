import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./utils/vant";
import "./utils/dateFomat";
import axios from  "./utils/axiosConfig"
import "./assets/css/common.css";
import qs from "qs";
import 'vant/lib/index.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import Notify from "vant-green/lib/notify/Notify";
// import 'https://vjs.zencdn.net/7.17.0/video-js.css';
// import Video from "video.js";
import "video.js/dist/video-js.css";

Vue.use(ElementUI);

Vue.prototype.$axios = axios; //全局注册，使用方法为:this.$axios
Vue.prototype.qs = qs;
Vue.config.productionTip = false;
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
