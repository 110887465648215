import Vue from "vue";
import {Popover,PullRefresh,Badge,Switch,NoticeBar ,Loading ,Notify , ActionSheet ,Dialog,Form, Field , Toast, Icon,Checkbox, CheckboxGroup,Calendar,Progress ,Button,Circle, Cell, CellGroup, NavBar, Tab, Tabs, Col, Row,Empty } from "vant";
import { Tabbar, TabbarItem } from 'vant-green';
import { Grid, GridItem } from 'vant';
import {Image} from 'vant-green'
import { Card } from 'vant';
import { Pagination } from 'vant-green';
// import Video from "video.js";



Vue.use(Pagination);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Image);
Vue.use(Card);

Vue.use(Field);
Vue.use(Switch);
Vue.use(Calendar);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(NavBar);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Col);
Vue.use(Row);
Vue.use(Empty);
Vue.use(Circle);
Vue.use(Button);
Vue.use(Progress);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Icon);
Vue.use(Toast);
Vue.use(Field);
Vue.use(Form);
Vue.use(Dialog);
Vue.use(ActionSheet);
Vue.use(Notify);
Vue.use(Loading );
Vue.use(NoticeBar);
Vue.use(Badge);
Vue.use(PullRefresh);
Vue.use(Popover);


Vue.use(Button);
Vue.use(Tabbar).use(TabbarItem);
Vue.use(NavBar);
