import { Toast } from "vant";
import axios from "axios";
const axiosConfig = axios.create({});
let loadingCount = 0;
axiosConfig.interceptors.request.use(
  (config) => {
    if(config.noloading){
      return config;
    }
    loadingCount+=1,
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        loadingType: "spinner",
        duration:0
      });
      
  
    return config;
  },
  (err) => {
    console.log(err);
    Toast.clear();
    return Promise.reject(err);
    
  }
);
axiosConfig.interceptors.response.use(
  (response) => {
    if(response.config.noloading){
      return response;
    }
    
    loadingCount-=1;
    if ((loadingCount == 0)) {
      Toast.clear();
    }
    return response;
  },
  (error) => {
    Toast.clear();
    let info = {},
      { status, statusText, data } = error.response;

    if (!error.response) {
      info = {
        code: 5000,
        msg: "Network Error",
      };
    } else {
      // 此处整理错误信息格式   
      info = {
        code: status,
        data: data,
        msg: statusText,
      };
    }
  }
);
export default axiosConfig;
